<script>
import Vue from "vue";

import { JournalProvider } from "@/providers";

export default Vue.extend({
  name: "BaseJournal",
  props: {
    step: Object
  },

  created() {
    if (this.step.status === "completed") {
      this.journalDone(this.step);
    }
  },

  methods: {
    async doJournal(response) {
      JournalProvider.doJournal(this.step.id, response).then(response =>
        this.journalDone(response.data)
      );
    },

    journalDone(step) {
      window.Event.trigger("JournalingCompleted", step);
    },

    cancelJournal() {
      this.$router.push({
        name: "home-page"
      });
    }
  }
});
</script>

<style scoped></style>
